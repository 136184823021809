<template>
    <div class="admin-tab-content-scroll">
        <div class="build-info">
            <string-param class="admin-param" type="text" title="backend branch" :value="buildInfo.branch" :readonly="true" />
            <string-param class="admin-param" type="text" title="backend commit" :value="buildInfo.commit" :readonly="true" />
            <string-param class="admin-param" type="text" title="backend tag" :value="buildInfo.tag" :readonly="true" />
        </div>
        <string-param v-for="(param,index) in params" :key="index" class="admin-param"
            type="text"
            :title="param.name"
            :value="param.value"
            :readonly="isReadOnly(index)"
            @change="onChangeParam(index, $event)">
        </string-param>
    </div>
</template>

<script>
import {readBuildInfo} from '@/api/AdminInterface'
import {loadAllParams, writeParam} from '@/api/PropertyInterface'
import StringParam from '@/components/StringParam'

export default {
    components: {
        StringParam
    },
    data() {
        return {
            buildInfo: {},
            params: []
        }
    },
    created() {
        readBuildInfo().then(buildInfo => {
            console.log(buildInfo);
            this.buildInfo = buildInfo
        })
        loadAllParams().then(params => {
            this.params = params;
            this.params.forEach(param => param.options = param.options ? JSON.parse(param.options) : {});
            // console.log(this.params);
        });
    },
    methods: {
        isReadOnly(index) {
            return this.params[index].options.readonly == 'true';
        },
        onChangeParam(index, value) {
            writeParam(this.params[index].name, value);
        }
    }
}
</script>

<style lang="scss" scoped>
.build-info {
    margin-bottom: 1rem;
}

.admin-tab-content-scroll {
    height: 100%;
    background-color: #eeeeee;
    overflow-y: auto;
}
</style>