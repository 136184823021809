import Vue from 'vue'
import Vuex from 'vuex'
import { authenticateUser, getAccessToken } from '@/api/Authentication'

Vue.use(Vuex)

const timeGuardSeconds = 15

function isTokenExpired(token) {
  const parts = token.split('.')
  if (parts[1] == undefined) return true
  try {
    const body = JSON.parse(window.atob(parts[1]))
    if (body.exp == undefined) return true
    const currentDate = new Date()
    const currentTime = Math.round(currentDate.getTime()/1000) + timeGuardSeconds +
                        currentDate.getTimezoneOffset()*60 + 10800
    return (currentTime > body.exp)
  } catch (error) {
    console.log(error)
  }
  return true
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default new Vuex.Store({
  state: {
    refreshToken: sessionStorage.getItem('authentication-token'),
    accessToken: null
  },
  mutations: {
    setRefreshToken: (state, val) => {
      state.refreshToken = val
      sessionStorage.setItem('authentication-token', val);
    },
    setAccessToken: (state, val) => state.accessToken = val,
    clearTokens: (state) => {
      state.refreshToken = null
      state.accessToken = null
      sessionStorage.removeItem('authentication-token')
    }
  },
  actions: {
    async authenticateUser(context, data) {
      try {
        const response = await authenticateUser(data.username, data.password, data.timeout);
        context.commit('setRefreshToken', response.refresh_token);
        context.commit('setAccessToken', response.access_token);
      } catch (error) {
        context.commit('clearTokens')
        throw error
      }
    },

    async updateToken(context) {
      for (;;) {
        if (context.state.refreshToken == null) return
        if (context.state.accessToken != null && !isTokenExpired(context.state.accessToken)) return
        if (context.state.updateTokenInProgress) {
          await sleep(100)
        } else {
          context.state.updateTokenInProgress = true
          break
        }
      }

      try {
        const response = await getAccessToken(context.state.refreshToken)
        context.commit('setRefreshToken', response.refresh_token);
        context.commit('setAccessToken', response.access_token)
      } catch (error) {
        console.log('update token error', error)
        context.commit('clearTokens')
      }

      context.state.updateTokenInProgress = false
    },

    clearTokens(context) {
      context.commit('clearTokens');
    }
  },
  getters: {
    getRefreshToken: (state) => state.refreshToken,
    getAccessToken: (state) => state.accessToken
  },
  modules: {
  }
})
