<template>
    <div class="admin-provider-edit">
        <input type="text" class="admin-provider-edit-name font-candera" v-model="editTitle">
        <button class="admin-provider-edit-ok" @click="onOkClick">OK</button>
        <button class="admin-provider-edit-cancel" @click="onCancelClick">Cancel</button>
        <map-container ref="map" class="admin-provider-edit-map"
                :bounds="bounds"
                @update="onUpdateBounds"/>
    </div>
</template>

<script>
import MapContainer from '@/components/MapContainer'

export default {
    components: {
        MapContainer
    },
    props: {
        title: String,
        property: Object
    },
    data() {
        return {
            editTitle: '',
            editProperty: {},
            bounds: []
        }
    },
    created() {
        this.editTitle = this.title;
        this.editProperty = this.property;
        this.bounds = this.property.bounds == undefined ? [] : this.property.bounds;
    },
    methods: {
        onOkClick() {
            this.editProperty.bounds = this.bounds;
            this.$emit('completed', { title: this.editTitle, property: this.editProperty });
        },
        onCancelClick() {
            this.$emit('canceled');
        },
        onUpdateBounds(bounds) {
            this.bounds = bounds;
        }
    }
}
</script>

<style lang="scss" scoped>
.admin-provider-edit {
    height: 100%;
    padding: 0.2rem;
    display: grid;
    grid-template-rows: 2rem auto;
    grid-template-columns: auto 10rem 10rem;
    gap: 0.2rem;
}

.admin-provider-edit-name {
    grid-row: 1/2;
    grid-column: 1/2;
    font-size: 1.2rem;
    padding-left: 1rem;
}

.admin-provider-edit-ok {
    grid-row: 1/2;
    grid-column: 2/3;
}

.admin-provider-edit-cancel {
    grid-row: 1/2;
    grid-column: 3/4;
}

.admin-provider-edit-map {
    grid-row: 2/3;
    grid-column: span 3;
}
</style>