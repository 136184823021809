<template>
    <div id="mapid"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
    props: {
        bounds: Array
    },
    data() {
        return {
            map: {}
        }
    },
    mounted() {
        // console.log('bounds', this.bounds);

        this.map = L.map('mapid', {
            zoomControl: true,
            preferCanvas: true,
            renderer: L.canvas(),
        });

        let mapServer = 'https://map.skyarc-dev.ru/tile';

        let osm = L.tileLayer(mapServer+'?p=osm&s={s}&z={z}&x={x}&y={y}.png', {
            attribution: '&copy; Аэроскрипт | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            subdomains: ['a', 'b', 'c'],
            maxZoom: 19,
            minZoom: 3
        });

        let mapbox = L.tileLayer(mapServer+'?p=mapbox&z={z}&x={x}&y={y}', {
            attribution: '&copy; <a href="https://apps.mapbox.com/feedback/">Mapbox</a> | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            maxZoom: 19,
            minZoom: 3,
            tileSize: 512,
            zoomOffset: -1
        });

        let mapbox_sat = L.tileLayer(mapServer+'?p=mapbox_sat&z={z}&x={x}&y={y}', {
            attribution: '&copy; <a href="https://apps.mapbox.com/feedback/">Mapbox</a> | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            maxZoom: 19,
            minZoom: 3,
            tileSize: 512,
            zoomOffset: -1
        });

        var tileMaps = {
            'Open Street Map': osm,
            'Mapbox Streets': mapbox,
            'Mapbox Satellite': mapbox_sat
        };

        L.control.layers(tileMaps, {}, {position: 'topleft'}).addTo(this.map);
        L.control.scale().addTo(this.map);
        mapbox.addTo(this.map);

        let poi = {
            middleSpbMsk: [57.80, 35.60]
        };

        this.map.setView(poi.middleSpbMsk, 5);

        if (this.bounds.length > 0) {
            this.moveMapToLocation(this.bounds);
        }

        this.tops = [];
        this.bounds.forEach(point => {
            this.tops.push(this.createBoundsTop(L.latLng(point)));
        });

        this.updateMiddles();
        this.updatePolygon();

        this.map.on('click', this.onMouseClick);
        this.map.on('mousemove', this.onMouseMove);
        this.map.on('mouseup', this.onMouseUp)
    },
    methods: {
        moveMapToLocation(points) {
            this.map.fitBounds(L.latLngBounds(points));
        },
        onPointUpdate() {
            this.updateMiddles();
            this.updatePolygon();
            this.sendUpdate();
        },
        sendUpdate() {
            let bounds = [];
            this.tops.forEach(top => bounds.push({lat:top.getLatLng().lat, lng:top.getLatLng().lng}));
            this.$emit('update', bounds);
        },
        onMouseClick(event) {
            // console.log('on mouse click at', event.latlng);
            if (this.tops == undefined) this.tops = [];
            if (this.tops.length < 3) {
                this.tops.push(this.createBoundsTop(event.latlng));
                this.onPointUpdate();
            }
        },
        onMouseMove(event) {
            this.moveMarkerTo(event.latlng);
        },
        onMouseUp() {
            this.draggedMarker = undefined;
            this.map.dragging.enable();
        },
        onMarkerPress(event) {
            this.map.dragging.disable();
            this.draggedMarker = event.target;
        },
        onMarkerContex(event) {
            if (this.tops.length > 3) {
                let index = this.tops.findIndex(top => top == event.target);
                if (index >= 0) {
                    event.target.remove();
                    this.tops.splice(index, 1);
                    this.onPointUpdate();
                }
            }
        },
        onMiddlePress(event) {
            let index = this.middles.findIndex(middle => middle == event.target);
            if (index >= 0) {
                let top = this.createBoundsTop(event.latlng);
                this.tops.splice(index+1, 0, top);
                this.onPointUpdate();
                top.fire('mousedown', event);
            }
        },
        createBoundsTop(latlng) {
            let marker = L.circleMarker(latlng).addTo(this.map);
            marker.on('mousedown', this.onMarkerPress);
            marker.on('contextmenu', this.onMarkerContex);
            return marker;
        },
        updateMiddles() {
            this.middles?.forEach(middle => middle.remove());
            this.middles = [];
            if (this.tops.length > 2) {
                for (let i = 0; i < this.tops.length-1; ++i) {
                    this.addMiddle(i, this.tops[i], this.tops[i+1]);
                }
                this.addMiddle(this.tops.length-1, this.tops[this.tops.length-1], this.tops[0]);
            }
        },
        addMiddle(index, from, to) {
            let latlng = L.latLng(
                (from.getLatLng().lat + to.getLatLng().lat) / 2,
                (from.getLatLng().lng + to.getLatLng().lng) / 2);
            let middle = L.circleMarker(latlng, { radius: 6, fillOpacity: 1.0 }).addTo(this.map);
            middle.on('mousedown', this.onMiddlePress);
            this.middles.splice(index, 0, middle);
        },
        updatePolygon() {
            if (this.polygon == undefined) {
                if (this.tops.length > 2) {
                    let bounds = [];
                    this.tops.forEach(top => bounds.push({lat:top.getLatLng().lat, lng:top.getLatLng().lng}));
                    this.polygon = L.polygon(bounds, {interactive: false}).addTo(this.map);
                }
            } else {
                let points = [];
                this.tops.forEach(top => points.push(L.latLng(top.getLatLng().lat, top.getLatLng().lng)));
                this.polygon.setLatLngs(points);
            }
        },
        moveMarkerTo(latlng) {
            if (this.draggedMarker) {
                // console.log('marker is dragged');
                this.draggedMarker.setLatLng(latlng);
                this.onPointUpdate();
            }
        }
    }
}
</script>

<style lang="scss">
#mapid{
    height: 100%;
}
</style>