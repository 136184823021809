<template>
    <div class="login-dialog">
        <div class="main-container">
            <div class="company">
                <img src="@/assets/aeroscript.svg">
            </div>
            <div class="product">
                <img src="@/assets/skyarc.svg">
            </div>
            <div class="content font-candara">
                <div class="login-container">
                    <div class="label">Логин:</div>
                    <input type="text" class="login font-candara text-color-default"
                            placeholder="E-mail пользователя"
                            title="Логином пользователя служит его актуальный e-mail"
                            v-model="login"/>
                    <div class="label">Пароль:</div>
                    <input type="password" class="password font-candara text-color-default"
                            placeholder="Пароль пользователя"
                            title="Пароль"
                            v-model="password"/>
                    <input type="button" value="Вход" class="enter-button font-candara text-color-selected"
                            @click="onEnterClicked" />

                    <!--   Сообщения -->
                    <div v-show="message.value" class="message-box"
                            :class="[message.class]" v-html="message.value"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TimeoutError } from '@/exception/TimeoutError.js'

export default {
    data() {
        return {
            login: '',
            password: '',
            message: {
                value: '',
                class: ''
            }
        }
    },
    methods: {
        onEnterClicked() {
            this.message.value = 'Идет обращение к серверу, ждите ...';
            this.message.class = 'text-color-ok';

            this.$store.dispatch('authenticateUser', {username: this.login, password: this.password, timeout: 5000})
                .then(() => {
                    this.message.value = '';
                    this.message.class = '';
                })
                .catch(error => {
                    if (error instanceof TimeoutError) {
                        this.message.value = 'Сервер не отвечает';
                        this.message.class = 'text-color-fail';
                    } else {
                        console.error(error);
                        this.message.value = 'Ошибка обращения к серверу';
                        this.message.class = 'text-color-fail';
                    }
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.login-dialog {
    height: 100%;
    background: linear-gradient(90deg, rgba(242, 97, 1, 0.1) 0%, rgba(197, 89, 17, 0.266433) 32.8%, rgba(197, 89, 17, 0.269118) 32.81%, rgba(1, 56, 87, 0.9) 100%), #FFFFFF;
}
.main-container{
    height: 100%;
    display: grid;
    grid-template-columns: 430px auto 462px;
    grid-template-rows: 94px auto 143px;
    grid-template-areas:
        "lngselect . product"
        ". content ."
        "company . ."
}
.company {
    margin-left: 20px;
    margin-bottom: 10px;
    grid-area: company;
    align-self: end;
    justify-self: start;
}

.product {
    margin-top: 20px;
    margin-right: 20px;
    grid-area: product;
    align-self: start;
    justify-self: end;
}
.content {
    grid-area: content;
    align-self: start;
    justify-self: center;
    color: #013857;
    z-index: 1;
    width: 350px;
}
.login-container{
	width: 350px;
}
.label{
    margin-top: 10px;
    margin-bottom: 10px;
}
.login{
    border-radius: 5px;
    width: 100%;
    height: $button-height;
}
.password{
    border-radius: 5px;
    width: 100%;
    height: $button-height;
}
.enter-button{
    margin-top: 30px;
    margin-bottom: 15px;
    border-radius: 5px;
    width: 100%;
    height: $button-height;
}

.message-box {
    margin-top: 30px;
    height: $button-height;
}
</style>