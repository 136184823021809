<template>
    <div>
        <string-param class="admin-param" type="text" title="E-mail" :value="eMail"
            @change="onEMailChange"></string-param>
        <string-param class="admin-param" type="text" title="Имя" :value="firstName"
            @change="onFirstNameChange"></string-param>
        <string-param class="admin-param" type="text" title="Фамилия" :value="lastName"
            @change="onLastNameChange"></string-param>
        <string-param class="admin-param" type="text" title="Телефон" :value="phone"
            @change="onPhoneChange"></string-param>
        <div class="new-user-roles-wrapper font-candara">
            <label>Роли</label>
            <div class="new-user-roles-list">
                <check-box class="blue-background new-user-role-wrapper" v-for="(role, index) in roles" :key="index"
                        :name="role.name"
                        :value="role.use"
                        @change="onChange(index, $event)"/>
            </div>
        </div>
        <div class="new-user-button-wrapper">
            <button class="new-user-button" @click="onCreateClick">Создать</button>
        </div>
    </div>
</template>

<script>
import {getRoles, createNewServiceman} from '@/api/AdminInterface.js'
import StringParam from '@/components/StringParam'
import CheckBox from '@/components/CheckBox'

export default {
    components: {
        StringParam,
        CheckBox
    },
    data() {
        return {
            eMail: '',
            phone: '',
            roles: [],
            lastName: '',
            firstName: ''
        }
    },
    created() {
        getRoles()
                .then(roles => {
                    this.roles = [];
                    roles.forEach(role => this.roles.push(
                        {
                            id: role,
                            use: false,
                            name: role.replace('ROLE_', '')
                        }
                    ));
                })
                .catch(error => {
                    console.error(error);
                });
    },
    methods: {
        clearData() {
            this.eMail = '';
            this.phone = '';
            this.roles.forEach(role => role.use = false);
            this.lastName = '';
            this.firstName = '';
        },
        onChange(index, event) {
            this.roles[index].use = event;
        },
        onCreateClick() {
            let roles = [];
            this.roles.forEach(role => {
                if (role.use) roles.push(role.id);
            });
            createNewServiceman(this.eMail, this.phone, this.firstName, this.lastName, roles)
                    .then(() => {
                        alert('Новый пользователь создан');
                        this.clearData();
                    })
                    .catch(() => {
                        alert('При создании нового пользователя произошла ошибка');
                    })
        },
        onEMailChange(value) {
            this.eMail = value;
        },
        onPhoneChange(value) {
            this.phone = value;
        },
        onLastNameChange(value) {
            this.lastName = value;
        },
        onFirstNameChange(value) {
            this.firstName = value;
        }
    }
}
</script>

<style lang="scss" scoped>
$new-user-button-height: 60px;
$new-user-button-width: 200px;
$new-user-button-background: #c5e6f2;
$new-user-button-color: #013857;
$new-user-button-border-radius: 15px;

$role-wrapper-height: 40px;
$role-wrapper-width: 120px;
$role-wrapper-margin: 20px;

.new-user-button-wrapper {
    display: flex;
    flex-flow: row-reverse;
}

.new-user-button {
    height: $new-user-button-height;
    width: $new-user-button-width;
    border: none;
    border-radius: 0px 0px $new-user-button-border-radius $new-user-button-border-radius;
    background-color: $new-user-button-background;
    color: $new-user-button-color;
    cursor: pointer;
}

.new-user-roles-wrapper {
    margin-top: $admin-param-top;
    display: flex;
}

.new-user-roles-wrapper > label {
    height: $admin-param-height;
    width: $admin-param-label-width;
    margin-right: $admin-param-label-right;
    color: $admin-param-color;
    background-color: $admin-param-label-background;
    line-height: $admin-param-height;
    padding-left: $admin-param-label-padding;
}

.new-user-roles-list {
    flex-grow: 1;
    background-color: $admin-param-edit-background;
    display: flex;
    flex-wrap: wrap;
}

.new-user-role-wrapper {
    margin-left: $role-wrapper-margin;
}
</style>