<template>
    <div>
        <div class="new-provider">
            <button @click="onNewClick">Новый провайдер</button>
        </div>
        <div class="admin-provider font-candara" v-for="(role, index) in roles" :key="index"
                @mouseenter="onMouseEnter(index)"
                @mouseleave="onMouseLeave(index)">
            <div class="admin-provider-name">
                {{role.title}}
            </div>
            <button v-show="index == highlighted" class="admin-provider-button" @click="onEditClick(index)">
                Редактировать
            </button>
            <button v-show="index == highlighted" class="admin-provider-button" @click="onDeleteClick(index)">
                Удалить
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        roles: Array
    },
    data() {
        return {
            highlighted: -1
        }
    },
    methods: {
        onMouseEnter(index) {
            this.highlighted = index;
        },
        onMouseLeave(index) {
            if (index == this.highlighted) this.highlighted = -1;
        },
        onNewClick() {
            this.$emit('add');
        },
        onEditClick(index) {
            this.$emit('edit', index);
        },
        onDeleteClick(index) {
            this.$emit('delete', index);
        }
    }
}
</script>

<style lang="scss" scoped>
$admin-provider-width: 200px;
$admin-provider-height: 50px;
$admin-provider-top: 4px;
$admin-provider-name-right: 4px;
$admin-provider-name-background: #e5e5e5;
$admin-provider-name-padding: 31px;
$admin-provider-color: #013857;
$admin-provider-reset-width: 200px;
$admin-provider-reset-background: #afcdd8;
$admin-provider-roles-border-radius: 5px;

.new-provider {
    display: flex;
    justify-content: end;
}

.new-provider button {
    width: $admin-service-button-width;
    height: $admin-service-button-height;
    margin-left: 2px;
    color: $admin-service-button-color;
    background-color: $admin-service-button-background;
    border: none;
    border-radius: $admin-service-button-border-radius;
    cursor: pointer;
}

.admin-provider {
    width: 100%;
    height: $admin-provider-height;
    margin-top: $admin-provider-top;
    display: flex;
    line-height: $admin-provider-height;
}

.admin-provider-name {
    /* width: $admin-provider-name-width); */
    flex-grow: 1;
    margin-right: $admin-provider-name-right;
    background-color: $admin-provider-name-background;
    padding-left: $admin-provider-name-padding;
    color: $admin-provider-color;
}

.admin-provider-button {
    width: $admin-provider-reset-width;
    margin-left: 2px;
    color: $admin-provider-color;
    background-color: $admin-provider-reset-background;
    border: none;
    border-radius: $admin-provider-roles-border-radius;
    cursor: pointer;
}
</style>