'use strict'

import { sendRequest } from "./ServerAccess"
import { HttpError } from '@/exception/HttpError'

export async function authenticateUser(username, password, timeout) {
    return await sendRequest('POST', '/api/login', {
        body: JSON.stringify({ username, password }),
        hideToken: true,
        json: true,
        timeout
    });
}

export async function getAccessToken(refreshToken) {
    let encoded = encodeURI(`/api/token?token=${refreshToken}`);
    let response = await fetch(encoded, { mode: 'cors' });
    if (!response.ok) {
        let json = await response.json()
        throw new HttpError(response.status, JSON.stringify(json));
    }
    return await response.json();
}
