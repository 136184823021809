'use strict'

import store from '@/store'
import Constants from '@/libs/Constants.js'
import { HttpError } from '@/exception/HttpError'
import { TimeoutError } from '@/exception/TimeoutError'

export async function sendRequest(method, url, params) {
    let headers = {};

    await store.dispatch('updateToken')

    let token = store.getters.getAccessToken
    if (token == undefined && params.hideToken != true) {
        throw new HttpError(401, 'token is undefined');
    }

    if (params.body != undefined) headers['Content-Type'] = 'application/json';
    if (token != undefined) headers['Authorization'] = 'Bearer ' + token;

    let options = { mode: 'cors', method, headers };
    if (params.body != undefined) options.body = params.body;

    var timer;
    if (params.timeout != undefined) {
        timer = setTimeout(() => { throw new TimeoutError(); }, params.timeout);
    }

    let encoded = encodeURI(`${Constants.HOST_URL}${url}`);

    let response = await fetch(encoded, options);

    if (timer != undefined) clearTimeout(timer);

    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            store.dispatch('clearTokens');
        }
        let json = await response.json()
        throw new HttpError(response.status, JSON.stringify(json));
    }

    if (params.json == true) {
        return await response.json();
    } else if (params.text == true) {
        return await response.text();
    } else if (params.blob == true) {
        return await response.blob();
    }
}
