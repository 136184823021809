<template>
    <div class="role-selector">
        <div class="roles-wrapper font-candara">
            <div class="roles-select-close-button" @click="onClose">
                <i class="fa fa-times"/>
            </div>
            <div class="roles-list">
                <check-box class="white-background" v-for="(role, index) in roles" :key="index"
                        :name="role.title"
                        :value="role.use"
                        @change="onChange(index, $event)"/>
            </div>
            <div class="role-select-apply" @click="onApply">
                Применить
            </div>
        </div>
    </div>
</template>

<script>
import CheckBox from '@/components/CheckBox'

export default {
    components: {
        CheckBox
    },
    data() {
        return {
            roles: []
        }
    },
    methods: {
        setRoles(roles) {
            this.roles = roles;
        },
        onChange(index, event) {
            // console.log('index =', index, 'event =', event);
            this.roles[index].use = event;
        },
        onClose() {
            this.$emit('close');
        },
        onApply() {
            this.$emit('apply', this.roles);
        }
    }
}
</script>

<style lang="scss" scoped>
$roles-wrapper-width: 550px;
$roles-wrapper-background: #afcdd8;
$roles-wrapper-border-radius: 15px;
$roles-select-close-button-size: 32px;
$roles-select-close-button-padding: 8px;
$role-select-apply-width: 200px;
$role-select-apply-height: 60px;
$role-select-apply-top: 10px;
$role-select-apply-border-radius: 15px;
$role-select-apply-background: #c5e6f2;
$role-select-apply-color: #013857;

.role-selector {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.roles-wrapper {
    width: $roles-wrapper-width;
    background-color: $roles-wrapper-background;
    border-radius: $roles-wrapper-border-radius $roles-wrapper-border-radius 0px 0px;
}

.roles-select-close-button {
    height: $roles-select-close-button-size;
    color: red;
    text-align: right;
    padding-right: $roles-select-close-button-padding;
    line-height: $roles-select-close-button-size;
    cursor: pointer;
}

.roles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

// .role-wrapper {
//     height: $role-wrapper-height);
//     width: $role-wrapper-width);
//     margin-right: $role-wrapper-margin);
// }

.role-select-apply {
    width: $role-select-apply-width;
    height: $role-select-apply-height;
    border-radius: $role-select-apply-border-radius $role-select-apply-border-radius 0px 0px;
    margin: $role-select-apply-top auto 0px auto;
    background-color: $role-select-apply-background;
    color: $role-select-apply-color;
    line-height: $role-select-apply-height;
    text-align: center;
    cursor: pointer;
}
</style>