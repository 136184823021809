<template>
        <div class="provider-tab">
            <provider-list v-if="mode=='list'"
                    :roles="roles"
                    @add="onAddProvider"
                    @edit="onEditProvider"
                    @delete="onDeleteProvider"/>
            <provider-edit v-if="mode=='edit'"
                    :title="providerTitle"
                    :property="providerProperty"
                    @completed="onEditProviderCompleted"
                    @canceled="onEditProviderCanceled"/>
        </div>
</template>

<script>
import {getProviderRoleList, getProviderRole, putProviderRole, deleteProviderRole} from '@/api/AdminInterface.js'
import ProviderList from '@/components/ProviderList'
import ProviderEdit from '@/components/ProviderEdit'

export default {
    components: {
        ProviderList,
        ProviderEdit
    },
    data() {
        return {
            mode: 'list',
            roles: [],
            providerTitle: '',
            providerProperty: {}
        }
    },
    created() {
        getProviderRoleList().then(roles => this.roles = roles);
    },
    methods: {
        onAddProvider() {
            this.providerTitle = '';
            this.providerProperty = {};
            this.mode = 'edit';
        },
        onEditProvider(index) {
            this.providerTitle = this.roles[index].title;
            getProviderRole(this.roles[index].title).then(roles => {
                if (roles.length > 0) {
                    this.providerProperty = roles[0].property;
                    this.mode = 'edit';
                }
            });
        },
        onDeleteProvider(index) {
            deleteProviderRole(this.roles[index].title).then(() => this.roles.splice(index, 1));
        },
        onEditProviderCompleted(edited) {
            if (edited.title.length > 0) {
                let index = this.roles.findIndex(r => r.title == edited.title);
                let role = {name: 'ROLE_REGION', title: edited.title, property: edited.property};
                putProviderRole(role).then(() => {
                    if (index == -1) {
                        this.roles.push(role);
                    } else {
                        this.roles[index] = role;
                    }
                });
            }
            this.mode = 'list';
        },
        onEditProviderCanceled() {
            this.mode = 'list';
        }
    }
}
</script>
