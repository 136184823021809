<template>
    <div class="admin-tab-content-scroll">
        <div class="admin-user font-candara" v-for="user in users" :key="user.id"
                @mouseenter="onMouseEnter(user.id)"
                @mouseleave="onMouseLeave(user.id)">
            <div class="admin-user-name">
                {{user.name}}
            </div>
            <div class="admin-user-roles" @click="onUserClick(user)">
                {{allUserRoles(user)}}
            </div>
            <button v-show="user.id == highlighted" class="admin-user-reset" @click="onResetClick(user)">
                Сбросить пароль
            </button>
            <button v-show="user.id == highlighted" class="admin-user-reset" @click="onDeleteClick(user)">
                Удалить
            </button>
        </div>
    </div>
</template>

<script>
import {getUserWithRoles, getRoles, setUserRoles, resetUserPassword, deleteUser} from '@/api/AdminInterface.js'

export default {
    data() {
        return {
            users: [],
            roles: [],
            highlighted: 0
        }
    },
    created() {
        this.refreshData();
    },
    methods: {
        allUserRoles(user) {
            let roles = '';
            user.roles.forEach(role => roles += role + ', ');
            return roles.substr(0, roles.length-2);
        },
        async refreshData() {
            try {
                this.users = await getUserWithRoles()
            } catch(error) {
                console.error(error)
            }
            try {
                let response = await getRoles()
                let roles = []
                response.forEach(role => roles.push({ id: role, title: role }));
                this.roles = roles
            } catch(error) {
                console.error(error);
            }
        },
        onUserClick(user) {
            let roles = [];
            // console.log(this.roles);
            this.roles.forEach(role => {
                roles.push({title: role.title, use: user.roles.includes(role.title)});
            });
            // console.log(roles);
            this.$emit('roles', {id: user.id, roles});
        },
        onResetClick(user) {
            resetUserPassword(user.name)
                .then(() => alert("Пароль сброшен"))
                .catch(error => {
                    console.error(error.code);
                    alert("Пароль сбросить не удалось")
                });
        },
        onDeleteClick(user) {
            console.log('Удалить пользователя', user.name);
            deleteUser(user.id).catch(error => console.error(error.code, error.message));
        },
        onMouseEnter(id) {
            this.highlighted = id;
        },
        onMouseLeave(id) {
            if (id == this.highlighted) this.highlighted = 0;
        },
        updateRoles(id, allRoles) {
            this.users.forEach(user => {
                if (user.id == id) {
                    let roles = [];
                    user.roles = [];
                    allRoles.forEach(role => {
                        if (role.use) {
                            user.roles.push(role.title);
                            roles.push(role.title);
                        }
                    });
                    console.log('set roles', roles);
                    setUserRoles(user.id, roles)
                        .catch(error => {
                            console.error(error);
                        });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$admin-user-top: 4px;
$admin-user-height: 50px;
$admin-user-color: #013857;
$admin-user-name-width: 613px;
$admin-user-name-right: 4px;
$admin-user-name-background: #e5e5e5;
$admin-user-name-padding: 31px;
$admin-user-roles-background: #ffffff;
$admin-user-roles-border-color: #013857;
$admin-user-roles-border-radius: 5px;
$admin-user-roles-padding: 16px;
$admin-user-reset-width: 200px;
$admin-user-reset-background: #afcdd8;

.admin-tab-content-scroll {
    height: 100%;
    background-color: #eeeeee;
    overflow-y: auto;
}

.admin-user {
    width: 100%;
    height: $admin-user-height;
    margin-top: $admin-user-top;
    display: flex;
    line-height: $admin-user-height;
}

.admin-user-name {
    width: $admin-user-name-width;
    margin-right: $admin-user-name-right;
    background-color: $admin-user-name-background;
    padding-left: $admin-user-name-padding;
    color: $admin-user-color;
}

.admin-user-roles {
    flex-grow: 1;
    background-color: $admin-user-roles-background;
    color: $admin-user-color;
    padding-left: $admin-user-roles-padding;
    border: 2px solid $admin-user-roles-background;
    cursor: pointer;
}

.admin-user-roles:hover {
    border-color: $admin-user-roles-border-color;
    border-radius: $admin-user-roles-border-radius;
}

.admin-user-reset {
    width: $admin-user-reset-width;
    margin-left: 2px;
    color: $admin-user-color;
    background-color: $admin-user-reset-background;
    border: none;
    border-radius: $admin-user-roles-border-radius;
    cursor: pointer;
}
</style>