<template>
    <label class="check-box-container">
        {{name}}
        <input type="checkbox" v-model="checked">
        <span class="check-box-checkmark"/>
    </label>
</template>

<script>
export default {
    props: {
        name: String,
        value: Boolean
    },
    computed: {
        checked: {
            get() { return this.value; },
            set(value) { this.$emit('change', value); }
        }
    }
}
</script>

<style lang="scss" scoped>
$check-box-container-height: 50px;
$check-box-checkmark-size: 24px;
$check-box-label-color: #013857;

.check-box-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    color: $check-box-label-color;
    height: $check-box-container-height;
    line-height: $check-box-container-height;
}

 /* Hide the browser's default checkbox */
.check-box-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    color: var(--check-box-label-color);
}

/* Create a custom checkbox */
.check-box-checkmark {
    position: absolute;
    top: calc(( $check-box-container-height - $check-box-checkmark-size ) / 2 );
    left: 0;
    height: $check-box-checkmark-size;
    width: $check-box-checkmark-size;
}


/* Create the checkmark/indicator (hidden when not checked) */
.check-box-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-box-container input:checked ~ .check-box-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-box-container .check-box-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #013857;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.white-background .check-box-checkmark {
    background-color: #ffffff;
}

.blue-background .check-box-checkmark {
    background-color: #c5e6f2;
}

/* On mouse-over, add a grey background color */
.white-background .check-box-container:hover input ~ .check-box-checkmark {
    background-color: #ffffff;
}

.blue-background .check-box-container:hover input ~ .check-box-checkmark {
    background-color: #c5e6f2;
}

/* When the checkbox is checked, add a blue background */
.white-background .check-box-container input:checked ~ .check-box-checkmark {
    background-color: #ffffff;
}

.blue-background .check-box-container input:checked ~ .check-box-checkmark {
    background-color: #c5e6f2;
}
</style>
