'use strict'

import { sendRequest } from "./ServerAccess"

export async function loadAllParams() {
    return await sendRequest('GET', '/api/property/all', {json: true});
}

export async function readParam(name) {
    return await sendRequest('GET', `/api/property?name=${name}`, {
        json: true
    });
}

export async function writeParam(name, value) {
    return await sendRequest('PUT', '/api/property', {
        body: JSON.stringify({ name, value })
    });
}
