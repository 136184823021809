<template>
    <div class="admin-service-tab font-candara">
        <div class="admin-service-buttons">
            <button class="admin-garbage-collector" @click="onGarbageCollect">Очистить базу данных</button>
        </div>
        <div v-if="message.length>0" class="admin-service-message">{{message}}</div>
        <div v-if="error.length>0" class="admin-service-error">{{error}}</div>
    </div>
</template>

<script>
import {runGarbageCollector} from '@/api/AdminInterface.js'

export default {
    data() {
        return {
            message: '',
            error: ''
        }
    },
    methods: {
        onGarbageCollect() {
            this.message = 'Выполняется очистка базы данных';
            runGarbageCollector()
                .then(() => {
                    this.error = '';
                    this.message = 'Очистка базы данных завершена';
                })
                .catch(error => {
                    this.message = '';
                    console.log(error);
                    this.error = `Ошибка выполнения очистки базы даных. Error = ${error.code} ${error.message}`;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
$admin-service-garbage-collector-width: 200px;
$admin-service-garbage-collector-height: 50px;
$admin-service-garbage-collector-color: #013857;
$admin-service-garbage-collector-background: #afcdd8;
$admin-service-garbage-collector-border-radius: 5px;

.admin-service-tab {
    width: 100%;
}

.admin-service-buttons {
    width: 100%;
    padding-top: 2rem;
    display: grid;
    align-content: center;
    justify-content: center;
}

.admin-garbage-collector {
    width: $admin-service-garbage-collector-width;
    height: $admin-service-garbage-collector-height;
    margin-left: 2px;
    color: $admin-service-garbage-collector-color;
    background-color: $admin-service-garbage-collector-background;
    border: none;
    border-radius: $admin-service-garbage-collector-border-radius;
    cursor: pointer;
}

.admin-service-message {
    padding-top: 5rem;
    text-align: center;
    color: black;
}

.admin-service-error {
    padding-top: 5rem;
    text-align: center;
    color: red;
}
</style>