'use strict'

import { sendRequest } from "./ServerAccess"

export async function getUserWithRoles() {
    let parts = []

    for (let page = 0; ; ++page) {
        let nextPage = await sendRequest('GET', `/api/admin/user/all?page=${page}&size=100`, { json: true })
        if (nextPage.length == 0) break
        parts.push(nextPage)
    }
    return [].concat(...parts)
}

export async function getRoles() {
    return await sendRequest('GET', '/api/admin/role/all', {
        json: true
    });
}

export async function setUserRoles(user, roles) {
    console.log('user', user, 'roles', roles);
    return await sendRequest('POST', '/api/admin/user/role', {
        body: JSON.stringify({ user, roles })
    });
}

export async function resetUserPassword(email) {
    return await sendRequest('PUT', '/api/admin/user/reset', {
        body: JSON.stringify({ email })
    });
}

export async function deleteUser(user_id) {
    return await sendRequest('DELETE', `/api/admin/user/${user_id}`, {});
}

export async function createNewServiceman(email, phone, firstname, lastname, roles) {
    return await sendRequest('POST', '/api/admin/user/add', {
        body: JSON.stringify({ email, phone, firstname, lastname, roles })
    });
}

export async function getProviderRoleList() {
    return await getProviderRole();
}

export async function getProviderRole(title) {
    let url = '/api/admin/role/custom?name=ROLE_REGION' + (title == undefined ? '' : `&title=${title}`);
    let response = await sendRequest('GET', url, {
        json: true
    });

    let roles = [];
    response.forEach(role => roles.push({
        roleId: role.role_id,
        name: role.name,
        title: role.title,
        property: JSON.parse(role.property)
    }));

    return roles;
}

export async function putProviderRole(role) {
    await sendRequest('POST', '/api/admin/role/custom', {
        body: JSON.stringify({
            name: role.name,
            title: role.title,
            property: JSON.stringify(role.property)
        })
    });
}

export async function deleteProviderRole(title) {
    await sendRequest('DELETE', `/admin/role/custom?title=${title}`, {});
}

export async function runGarbageCollector() {
    return await sendRequest('POST', '/api/admin/gc/run', {});
}

export async function readBuildInfo() {
    return await sendRequest('GET', '/api/admin/buildinfo', {json: true});
}
