<template>
    <div class="font-candara">
        <label>{{title}}</label>
        <input class="font-candara" :type="type" v-model="local" :readonly="readonly">
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        title: String,
        value: String,
        readonly: Boolean
    },
    computed: {
        local: {
            get() { return this.value; },
            set(value) { this.$emit('change', value); }
        }
    }
}
</script>
