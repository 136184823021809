<template>
    <div class="admin-page">
        <div class="admin-tab-bar">
            <div class="admin-page-logo">
                <img src="@/assets/icon_view.svg" class="icon-skyarc">
            </div>
            <div :class="getConfigTabClass()" @click="selectConfig">
                Параметры
            </div>
            <div :class="getProviderTabClass()" @click="selectProvider">
                Региональные провайдеры
            </div>
            <div :class="getUsersTabClass()" @click="selectUsers">
                Пользователи
            </div>
            <div :class="getNewUserTabClass()" @click="selectNewUser">
                Новый
            </div>
            <div :class="getServiceTabClass()" @click="selectService">
                Обслуживание
            </div>
        </div>
        <div class="admin-tab-wide-separator"></div>
        <config-tab ref="config_tab" v-if="isConfigTabSelected()"/>
        <provider-tab ref="provider-tab" v-if="isProviderTabSelected()"/>
        <user-tab ref="user_tab" v-if="isUsersTabSelected()" @roles="onRoles"/>
        <new-user ref="new_user" v-if="isNewUserTabSelected()"/>
        <service-tab ref="service_tab" v-if="isServiceTabSelected()"/>
        <role-selector ref="role_selector" v-show="showRoleSelector"
                @close="onRoleSelectorClose"
                @apply="onRoleSelectorApply"/>
    </div>
</template>

<script>
import ConfigTab from '@/components/ConfigTab'
import ProviderTab from '@/components/ProviderTab'
import UserTab from '@/components/UserTab'
import NewUser from '@/components/NewUser'
import ServiceTab from '@/components/ServiceTab'
import RoleSelector from '@/components/RoleSelector'

export default {
    components: {
        ConfigTab,
        ProviderTab,
        UserTab,
        NewUser,
        ServiceTab,
        RoleSelector
    },
    data() {
        return {
            selectedTab: '', // "config", "provider", "users", "new-user", "service"
            userId: 0,
            showRoleSelector: false
        }
    },
    created() {
        this.selectConfig();
    },
    methods: {
        getTabClass() {
            return {
                'admin-tab': true,
                'font-candara': true
            };
        },
        getConfigTabClass() {
            let cssclass = this.getTabClass();
            cssclass['selected-tab'] = this.isConfigTabSelected();
            return cssclass;
        },
        getProviderTabClass() {
            let cssclass = this.getTabClass();
            cssclass['selected-tab'] = this.isProviderTabSelected();
            return cssclass;
        },
        getUsersTabClass() {
            let cssclass = this.getTabClass();
            cssclass['selected-tab'] = this.isUsersTabSelected();
            return cssclass;
        },
        getNewUserTabClass() {
            let cssclass = this.getTabClass();
            cssclass['selected-tab'] = this.isNewUserTabSelected();
            return cssclass;
        },
        getServiceTabClass() {
            let cssclass = this.getTabClass();
            cssclass['selected-tab'] = this.isServiceTabSelected();
            return cssclass;
        },
        isConfigTabSelected() {
            return this.selectedTab == 'config';
        },
        isProviderTabSelected() {
            return this.selectedTab == 'provider';
        },
        isUsersTabSelected() {
            return this.selectedTab == 'users';
        },
        isNewUserTabSelected() {
            return this.selectedTab == 'new-user';
        },
        isServiceTabSelected() {
            return this.selectedTab == 'service';
        },
        selectConfig() {
            this.selectedTab = 'config';
        },
        selectProvider() {
            this.selectedTab = 'provider';
        },
        selectUsers() {
            this.selectedTab = 'users';
        },
        selectNewUser() {
            this.selectedTab = 'new-user';
        },
        selectService() {
            this.selectedTab = 'service';
        },
        onRoles(data) {
            this.userId = data.id;
            this.$refs.role_selector.setRoles(data.roles);
            this.showRoleSelector = true;
        },
        onRoleSelectorClose() {
            this.showRoleSelector = false;
        },
        onRoleSelectorApply(roles) {
            console.log(roles);
            this.$refs.user_tab.updateRoles(this.userId, roles);
            this.showRoleSelector = false;
        }
    }
}
</script>

<style lang="scss">
.admin-param {
    margin-top: $admin-param-top;
    display: flex;
}

.admin-param label {
    height: $admin-param-height;
    width: $admin-param-label-width;
    margin-right: $admin-param-label-right;
    color: $admin-param-color;
    background-color: $admin-param-label-background;
    line-height: $admin-param-height;
    padding-left: $admin-param-label-padding;
}

.admin-param input {
    height: $admin-param-height;
    flex-grow: 1;
    background: none;
    border: 2px solid $admin-param-edit-background;
    color: $admin-param-color;
    background-color: $admin-param-edit-background;
    padding-left: $admin-param-edit-padding;
    text-overflow: ellipsis;
}

.admin-param input:hover {
    border-color: $admin-param-edit-border-color;
    border-radius: $admin-param-edit-border-radius;
}
</style>

<style lang="scss" scoped>
$admin-tab-bar-height: 80px;
$admin-tab-separator-height: 70px;
$admin-page-logo-size: 40px;
$admin-page-logo-top: 27px;
$admin-page-logo-left: 26px;
$admin-page-logo-right: 17px;
$top-bar-logo-height: 40px;
$top-bar-logo-margin: 10px;
$admin-tab-height: 60px;
$admin-tab-width: 250px;
$admin-tab-left: 15px;
$admin-tab-bar-background: #afcdd8;
$admin-tab-bar-selected-background: #c5e6f2;
$admin-tab-color: #013857;
$admin-border-radius: 8px;

.admin-page {
    height: 100%;
    background: linear-gradient(90deg, rgba(242, 97, 1, 0.1) 0%, rgba(197, 89, 17, 0.266433) 32.8%, rgba(197, 89, 17, 0.269118) 32.81%, rgba(1, 56, 87, 0.9) 100%), #FFFFFF;
    display: grid;
    grid-template-rows: $admin-tab-bar-height $admin-tab-separator-height auto;
}

.admin-tab-bar {
    display: flex;
}

.admin-page-logo {
    height: $admin-page-logo-size;
    width: $admin-page-logo-size;
    margin-top: $admin-page-logo-top;
    margin-left: $admin-page-logo-left;
    margin-right: $admin-page-logo-right;
}

.top-bar .icon-skyarc {
    margin-left: $top-bar-logo-margin;
}
.icon-skyarc{
    height: $top-bar-logo-height;
}

.admin-tab {
    width: $admin-tab-width;
    height: $admin-tab-height;
    line-height: $admin-tab-height;
    border-radius: $admin-border-radius $admin-border-radius 0px 0px;
    margin-top: calc($admin-tab-bar-height - $admin-tab-height);
    margin-left: $admin-tab-left;
    background-color: $admin-tab-bar-background;
    color: $admin-tab-color;
    text-align: center;
    cursor: pointer;
}

.admin-tab-bar .selected-tab {
    background-color: $admin-tab-bar-selected-background;
}

.admin-tab-wide-separator {
    background-color: #c5e6f2;
}
</style>