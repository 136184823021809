<template>
  <login-dialog v-if="token == undefined"/>
  <authenticated v-else/>
</template>

<script>
import LoginDialog from '@/components/LoginDialog.vue'
import Authenticated from '@/components/Authenticated.vue'

export default {
  name: 'App',
  components: {
    LoginDialog,
    Authenticated
  },
  computed: {
    token: { get() { return this.$store.getters.getRefreshToken; } }
  }
}
</script>

<style lang="scss">
@import "./assets/awesome/font-awesome.css";

*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box; /*не работает в body*/
}

html, body, #app {
  height: 100%;
}

@font-face{
  font-family: Candara;
  src: url(./assets/Candara.woff);
  font-style: normal;
  font-weight: normal;
}

.font-candara {
  font-family: Candara, sans-serif;
  font-size: 1.2rem;
}

.text-color-default{
  color: #013857;
  background-color: #AFCDD8;
}

.text-color-selected{
  color: #AFCDD8;
  background-color: #013857;
}

.text-color-ok{
  color: #013857;
  background-color:rgba(0, 255, 0, 0.25);
  border: none;
}

.text-color-fail{
  color: #013857;
  background-color:rgba(255, 0, 0, 0.25);
  border: solid 2px #F06322;
}

.text-color-warning{
  color:  #013857;
  border: solid 2px yellow;
  background-color:rgba(255, 255, 0, 0.25);
}
</style>
